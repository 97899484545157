<template>
	<div
		class="c-navgation__container row max-width-grid bp-1024:padding-x-24 bp-1280:padding-x-0"
	>
		<div class="c-navigation-mobile bp-1024:display-none width-100">
			<ul class="c-navigation__level-1 reset-list">
				<li
					class="c-navigation__item--level-1 border-b"
					:class="[
						item.items && item.items.length
							? 'c-navigation__parent'
							: undefined,
						item.is_open ? 'is-open' : undefined,
					]"
					v-for="(item, index) in data"
					:key="index"
				>
					<a
						class="c-navigation__url c-navigation__url--level-1 align-items-center display-flex font-size-16 font-weight-400 justify-content-space-between header-font padding-y-24 padding-x-32 text-decoration-none text-transform-uppercase transition"
						tabindex="0"
						:aria-label="item.title"
						:aria-haspopup="
							item.items && item.items.length ? 'true' : 'false'
						"
						:aria-expanded="item.is_open ? 'true' : 'false'"
						:title="item.title"
						href="javascript:void(0)"
						@click.prevent="
							item.items && item.items.length
								? toggle_menu_item(item)
								: undefined
						"
						@keyup.enter="
							item.items && item.items.length
								? toggle_menu_item(item)
								: undefined
						"
						v-if="item.items.length || item.url === ''"
					>
						<span>{{ item.title }}</span>
						<chevron-right-icon
							class="margin-l-auto transition"
							size="1x"
						/>
					</a>
					<navigation-link
						class="c-navigation__url c-navigation__url--level-1 align-items-center display-flex font-size-16 font-weight-400 justify-content-space-between header-font padding-y-24 padding-x-32 text-decoration-none text-transform-uppercase transition"
						:_aria-label="item.title"
						:_target="item.open_in_new_window ? '_blank' : '_self'"
						:_title="item.title"
						:url="item.url ? item.url : ''"
						_external-icon
						_tabIndex="0"
						v-else
					>
						{{ item.title }}
						<chevron-right-icon
							class="margin-l-auto transition"
							size="1x"
						></chevron-right-icon>
					</navigation-link>
					<div
						class="c-navigation__level-2"
						v-if="item.items.length"
						:class="item.is_open ? 'is-open' : undefined"
					>
						<ul
							class="reset-list bg-color-black bp-1024:height-100"
						>
							<li
								class="c-navigation__item--level-2 border-b"
								v-for="(_item, index) in item.items"
								:key="index"
								:class="[
									_item.separator
										? 'c-navigation__separator font-size-16 header-font'
										: undefined,
									_item.items && _item.items.length
										? 'display-flex align-items-center'
										: undefined,
								]"
							>
								<navigation-link
									_class="c-navigation__url c-navigation__url--level-2 display-block font-size-16 padding-y-12 padding-x-32 text-black text-decoration-none hover:text-decoration-underline"
									v-if="!_item.separator"
									:url="_item.url ? _item.url : ''"
									:tabindex="item.is_open ? '0' : undefined"
									_external-icon
									>{{ _item.title }}</navigation-link
								>
								<span
									class="display-block padding-y-12 padding-x-32"
									v-else
									>{{ _item.title }}</span
								>
							</li>
						</ul>
					</div>
				</li>
			</ul>
		</div>
		<div
			class="c-navigation-desktop display-none bp-1024:display-block width-100"
		>
			<ul class="c-navigation__level-1 display-flex reset-list width-100">
				<li
					class="c-navigation__item--level-1 padding-x-12"
					:class="[
						index === 0 ? 'padding-l-0' : undefined,
						index === data.length - 1 ? 'padding-r-0' : undefined,
					]"
					v-for="(item, index) in data"
					:key="index"
				>
					<navigation-link
						_class="c-navigation__url c-navigation__url--level-1 font-size-18 font-weight-400 header-font text-transform-uppercase text-decoration-none"
						:url="item.url != '' ? item.url : 'javascript:void(0)'"
						:_external-icon="true"
						>{{ item.title }}</navigation-link
					>
					<ul
						class="c-navigation__level-2 margin-t-16 reset-list"
						v-if="item.items.length"
					>
						<li
							class="c-navigation__item--level-2 margin-b-8"
							:class="
								_item.separator
									? 'c-navigation__separator header-font'
									: undefined
							"
							v-for="(_item, index) in item.items"
							:key="index"
						>
							<navigation-link
								_class="c-navigation__url c-navigation__url--level-2 align-items-center display-flex font-size-16 text-decoration-none hover:text-decoration-underline"
								v-if="!_item.separator"
								:_aria-label="_item.title"
								:url="_item.url ? _item.url : ''"
								_tab-index="0"
								:_target="
									_item.open_in_new_window
										? '_blank'
										: '_self'
								"
								:_title="_item.title"
								_external-icon
							>
								<chevron-right-icon
									class="margin-r-4 transition"
									size="1x"
								></chevron-right-icon>
								<span>{{ _item.title }}</span>
							</navigation-link>
							<span v-else>{{ _item.title }}</span>
						</li>
					</ul>
				</li>
				
			</ul>
		</div>
		<div>
			<!--BE IXF: The following <div> block needs to be placed in the location where the link block will be displayed-->
			<!--BE IXF: For your website, the location is   -->
			<div class="be-ix-link-block"><!--Link Block Target Div--></div>
			<!--BE IXF: end -->
		</div>
	</div>
</template>

<script>
import { ChevronRightIcon } from 'vue-feather-icons';
import NavigationLink from '@/components/common/NavigationLink';

export default {
	name: 'MainNavigationFooter',

	components: {
		ChevronRightIcon,
		NavigationLink,
	},

	props: {
		data: {
			required: true,
			type: Array,
			default: () => [],
		},
	},

	methods: {
		open_menu_item(item) {
			item.is_open = true;
		},

		close_menu_item(item) {
			item.is_open = false;
		},

		toggle_menu_item(item) {
			this.data.forEach(_item => {
				if (_item.id != item.id && _item.is_open) {
					_item.is_open = false;
				}
			});

			item.is_open = !item.is_open;
		},

		close_dropdowns() {
			this.data.forEach(item => {
				if (item.is_open) {
					this.close_menu_item(item);
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.c-navigation-mobile {
	.c-navigation {
		//for naming
		&__item--level-1 {
			border-color: var(--color-dark-theme-stroke);
			&.is-open {
				.c-navigation__url--level-1 svg {
					transform: rotate(90deg);
				}
			}
		}
		&__level-2 {
			height: 0;
			overflow: hidden;

			&.is-open {
				height: auto;
				overflow: auto;
			}
		}
		&__item--level-2 {
			border-color: var(--color-dark-theme-stroke);

			&:first-child {
				border-top: 1px solid var(--color-dark-theme-stroke);
			}
			&:last-child {
				border-bottom: none;
			}
		}
	}
}
.c-navigation-desktop {
	border-color: var(--color-dark-theme-stroke);
	.c-navigation {
		//for naming
		&__item--level-1 {
			flex: 1 1 0px;
		}
		&__separator {
			color: #fff;
		}
		&__item--level-2 {
			&:last-child {
				margin-bottom: 0 !important;
			}
		}
	}
}
</style>

<style lang="scss">
.c-navigation--footer {
	.c-navigation {
		//for naming
		&__url {
			color: #fff;
		}
	}
	.c-navigation-desktop {
		.c-navigation__url {
			&:hover,
			&:focus {
				svg {
					transform: translateX(3px);
				}
			}
		}
	}
	@media screen and (min-width: 1024px) {
		border-bottom: 1px solid var(--color-dark-theme-stroke);
	}
}
</style>
